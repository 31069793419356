import { USER_CURRENT_QUERY_GQL } from "gql/userCurrentQuery.gql";
import { UserCurrentQuery } from "__generated__/UserCurrentQuery";
import Login from "pages/auth/login";
import { useRouter } from "next/router";
import useQuery from "hooks/useQuery";
import * as Sentry from "@sentry/react";
import useMixpanel from "hooks/useMixpanel";
import { getCompanyIDAndProjectID } from "helpers/utils";

interface Props {
  children: React.ReactNode;
}

/**
 * Gatekeeper (noun): a person or thing that controls access to something.
 *
 * Gatekeeper verifies that our users are authenticated before allowing them access to parts of our application.
 */
export default function Gatekeeper(props: Props) {
  const router = useRouter();
  const { companyID, projectID } = getCompanyIDAndProjectID();
  const mixpanel = useMixpanel();
  const isSSR = typeof window === "undefined";
  if (router.route === "/_error") {
    // 404
    return <>{props.children}</>;
  }
  const pageRequiresAuth = !router.pathname.startsWith("/auth/");
  // run UserCurrentQuery.
  // if our user is authenticated, we will get data back (from either the backend or our local cache).
  // if we do not get data, we are not logged in.
  const { loading, data } = useQuery<UserCurrentQuery>(USER_CURRENT_QUERY_GQL, {
    errorPolicy: "ignore",
    skip: !pageRequiresAuth || isSSR,
  });

  if (pageRequiresAuth && (isSSR || loading)) {
    // loading/SSR: return the unhydrated render (skeleton) of the page the user requested
    return <>{props.children}</>;
  } else if (pageRequiresAuth && !data) {
    // user is not authenticated; show Login component
    // TODO: Potential circular reference - Should components import from pages?
    // log user out of Sentry/Mixpanel
    Sentry.setUser(null);
    mixpanel.reset();
    return <Login />;
  } else {
    const currentUser = data?.currentUser;
    if (currentUser) {
      // identify to Sentry/Mixpanel

      const { email, id } = currentUser;

      Sentry.setUser({ email, id });
      mixpanel.identify(id);
      mixpanel.register({ Platform: "web app" });

      let fullName = "";
      const { firstName, lastName } = currentUser;
      if (firstName && lastName) {
        fullName = `${firstName} ${lastName}`;
      }

      pendo.updateOptions({
        visitor: {
          id: currentUser.id,
          email: currentUser.email,
          full_name: fullName,

          // Metadata to be passed thru to Intercom.
          // May be redundant, but these specific fields are needed for
          // Intercom's user profile.
          user_id: currentUser.id,
          name: fullName,
        },
        account: {
          id: "BASE_APP",
          name: "BASE_APP",
        },
      });

      // add custom sentry tags
      Sentry.setTag("companyID", companyID);
      Sentry.setTag("projectID", projectID);
    }
    return <>{props.children}</>;
  }
}
