import Amplify from "aws-amplify";
import "antd/dist/antd.less";
import "styles/LNQglobalVars.scss";
import "styles/index.less";
import Gatekeeper from "components/auth/Gatekeeper";
import { SelfXSSWarning } from "components/base/SelfXSSWarning";
import { useApollo } from "config/apolloClient";
import { ApolloProvider } from "@apollo/client";
// eslint-disable-next-line no-restricted-imports
import mixpanel from "mixpanel-browser";
import Head from "next/head";
import usePendo from "hooks/usePendo";

function App({ Component, pageProps }) {
  // https://github.com/sheerun/extracted-loader/issues/11#issue-453094382
  if ((module as any).hot) {
    (module as any).hot.addStatusHandler((status: string) => {
      if (typeof window !== "undefined" && status === "ready") {
        (window as any).__webpack_reload_css__ = true;
      }
    });
  }

  // don't run for SSR/static export
  if (typeof window !== "undefined") {
    // Mixpanel
    if (process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) {
      mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN);
    }
    // Amplify
    Amplify.configure({
      Auth: {
        // Needed to correctly upload directly to S3 using AWS Amplify
        // https://docs.amplify.aws/lib/storage/getting-started/q/platform/js#manual-setup-import-storage-bucket
        identityPoolId: process.env.NEXT_PUBLIC_COGNITO_IDENTITY_POOL,
        authenticationFlowType: "USER_PASSWORD_AUTH",
        region: process.env.NEXT_PUBLIC_COGNITO_REGION,
        userPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.NEXT_PUBLIC_COGNITO_APP_CLIENT_ID,
      },
      Storage: {
        // Needed to correctly upload directly to S3 using AMW Amplify
        // https://docs.amplify.aws/lib/storage/getting-started/q/platform/js#manual-setup-import-storage-bucket
        bucket: process.env.NEXT_S3_UPLOAD_BUCKET,
        region: process.env.NEXT_PUBLIC_COGNITO_REGION,
        // Amplify automatically adds prefixes for the bucket we store to - we have our own path to upload so we
        // customize the prefixes here - https://docs.amplify.aws/lib/storage/configureaccess/q/platform/js#customization
        customPrefix: {
          public: "manual/",
          protected: "manual/",
          private: "manual/",
        },
      },
    });

    // Pendo
    usePendo();
  }

  // https://github.com/vercel/next-plugins/issues/282
  // https://github.com/vercel/next.js/issues/5291
  // https://github.com/vercel/next.js/issues/5413
  if (process.env.NODE_ENV !== "production") {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    require("next/router").default.events.on("routeChangeError", (err, url) => {
      if (err.stack.startsWith("TypeError: Cannot read property")) {
        location.href = url;
      }
    });
  }

  // apollo
  const apolloClient = useApollo(pageProps);

  return (
    <>
      <Head>
        <title>DADO | Construction Search Engine</title>
      </Head>
      <SelfXSSWarning />
      <ApolloProvider client={apolloClient}>
        <Gatekeeper>
          <Component {...pageProps} />
        </Gatekeeper>
      </ApolloProvider>
    </>
  );
}

export default App;
