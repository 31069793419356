import { useEffect } from "react";

/**
 * Initializes Pendo once the window's "load" event is triggered.
 * This makes pendo less likely to fail since its library *should* be loaded
 * before initialize() and getVisitorId() are called.
 */
const usePendo = () => {
  function initializePendo() {
    pendo &&
      pendo.initialize({
        visitor: {
          // For Intercom to initiate w/o an auth'd DADO user
          anonymous_id: pendo.getVisitorId(),
        },
      });
  }

  useEffect(() => {
    window.addEventListener("load", initializePendo);

    return () => window.removeEventListener("load", initializePendo);
  }, []);
};

export default usePendo;
