import { isMixpanelEnabled } from "helpers/utils";
// eslint-disable-next-line no-restricted-imports
import mixpanelBrowser, { Mixpanel } from "mixpanel-browser";

// eslint-disable-next-line @typescript-eslint/no-empty-function
function NOOP() {}

const STUB: any = {
  identify: NOOP,
  register: NOOP,
  reset: NOOP,
};

// https://github.com/mixpanel/mixpanel-js/issues/133
// because Mixpanel throws errors when it has not been initialized, we have to
// guard against errors being thrown when calling Mixpanel methods.  :-(
// here we check whether Mixpanel's token has been set in our ENV.  If so, we assume Mixpanel has been init (in _app.tsx)
// and we return the real Mixpanel module. otherwise, we return a method stub.
export default function useMixpanel() {
  const mixpanelOrStub: Pick<Mixpanel, "identify" | "register" | "reset"> = isMixpanelEnabled() ? mixpanelBrowser : STUB;
  return mixpanelOrStub;
}
